import React, { useCallback } from 'react';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import convertToSlug from 'helpers/stringToSlug';

import { section, iframe } from './style.module.scss';

const ACC = [
  {
    title: 'VR room',
    iframe: 'https://go.screver.com/capptoo/vote-vr',
  },
  {
    title: 'Gamification room',
    iframe: 'https://go.screver.com/capptoo/vote-gamification',
  },
  {
    title: 'Omnichannel room',
    iframe: 'https://go.screver.com/capptoo/vote-omnichannel',
  },
];

const Vote = () => {
  const handleClick = useCallback((e) => {
    if (typeof window !== 'undefined' && ACC[e]?.title !== 'undefined') {
      window.location.hash = convertToSlug(ACC[e]?.title);
    }
  }, []);

  return (
    <section className={section}>
      <Accordion
        allowZeroExpanded={true}
        preExpanded={[0]}
        onChange={(e) => handleClick(e[0])}
      >
        {ACC.length > 0 ? (
          ACC.map((item, idx) => {
            return (
              <AccordionItem key={item.title + idx} uuid={idx}>
                <AccordionItemHeading id={convertToSlug(item.title)}>
                  <AccordionItemButton>{item.title}</AccordionItemButton>
                </AccordionItemHeading>

                <AccordionItemPanel>
                  <iframe
                    className={iframe}
                    title={item.title}
                    src={item.iframe}
                    frameBorder="0"
                  ></iframe>
                  {/* <SurveyIframe src={item.iframe} /> */}
                </AccordionItemPanel>
              </AccordionItem>
            );
          })
        ) : (
          <></>
        )}
      </Accordion>
    </section>
  );
};

export default Vote;
