import React from 'react';

import HeaderCommon from 'sections/ScreverOcxProgram/HeaderCommon';
import Vote from 'sections/ScreverBtqProgram/Vote';
import FooterCommon from 'sections/ScreverOcxProgram/FooterCommon';
import SEO from 'sections/Layout/SEO';
import OcxHelmet from 'sections/Layout/SEO/OcxHelmet';

const PAGES = [
  {
    title: 'Program',
    url: '/events/screver-btq-program/program',
  },
  {
    title: 'Cinema',
    url: '/events/screver-btq-program/cinema',
  },
  {
    title: '✌🏻feedback',
    url: '/events/screver-btq-program/feedback',
  },
];

const seo = {
  title: 'Screver Boutique Event: Let’s talk & Co-work | Vote',
  metaDesc:
    "Vote for the challenge. We're rolling out the red carpet for a unique three-day get-together in Zurich, exclusively for our special clients and close contacts.",
  image:
    'https://wp.screver.com/wp-content/uploads/2023/08/screver-btq-meta-img.jpg',
  opengraphUrl: 'https://screver.com/events/screver-btq-program/vote/',
};

const CasinoInstructions = () => {
  return (
    <>
      <OcxHelmet />

      <SEO seo={seo} themeColor={'#151517'} />

      <div className="scr-ocx-program scr-btq-event">
        <HeaderCommon
          title="Hackathons. Rate a challenge for:"
          url="/events/screver-btq-program/"
        />

        <main>
          <div className="ocx-prog-wrap">
            <Vote />
          </div>
        </main>

        <FooterCommon linksToPages={PAGES} />
      </div>
    </>
  );
};

export default CasinoInstructions;
